import { useState, useEffect } from 'react';
import React from 'react';
import { useAccount } from 'wagmi';
import { getEthersSigner, getMoonizensNFTContract, fromBigNum } from '../../services/web3.service';
import { Box, Grid, Stack, Typography } from '@mui/material';

import HomeStyled from './index.styled';

import ProfileBox from '../../components/Pages/Home/ProfileBox';
import ClaimBox from '../../components/Pages/Home/ClaimBox';
import WalletViewer from '../../components/Pages/Home/WalletViewer';
import MoonizensOwned from '../../components/Pages/Home/MoonizensOwned';
import Quadrocar from '../../components/Pages/Home/Quadrocar';
import GradientBorderBox from '../../components/GradientBorderBox';

import ImageAvatar from '../../assets/images/avatar.png';

const Home = () => {
  const [maxSupply, setMaxSupply] = useState('');
  const [leftSupply, setLeftSupply] = useState('');
  const [keyName, setKeyName] = useState('');
  const [claimAction, setClaimAction] = useState(false);
  const [pfp, setPFP] = useState(/* JSON.parse(localStorage.getItem('pfpArtifact')).pfp ||  */'avatar.png');
  const { isConnected, address } = useAccount();

  const handlepfpChange = (text) => {
      if(`Moonizen Genesis Images/${text}` !== pfp){
        setPFP(`Moonizen Genesis Images/${text}`);
        const pfpObject = {address: address , pfp: `Moonizen Genesis Images/${text}`};
        const keyName_temp = 'pfpArtifact_' + address;
        localStorage.setItem(keyName_temp, JSON.stringify(pfpObject));
      } else{
        setPFP('avatar.png');
        localStorage.removeItem(keyName);
      }
  }

  const handleClaimAction = () => {
    setClaimAction(claimAction => !claimAction);
  }

  const fetchNFTData = async () => {
    const signer_ = null;
    const nftContract = await getMoonizensNFTContract(signer_);
    const maxSupply_ = await nftContract.maxSupply();
    setMaxSupply("TOTAL SUPPLY: " + maxSupply_)
    const totalSupply = await nftContract.totalSupply();
    const leftSupply_ = fromBigNum(maxSupply_ - totalSupply, 0);
    setLeftSupply("SUPPLY LEFT: " + leftSupply_)
  }

  useEffect(()=>{
    if(isConnected){
      if(JSON.parse(localStorage.getItem(keyName))){
        setPFP(JSON.parse(localStorage.getItem(keyName)).pfp)}
    } else{
      setPFP('avatar.png')
    }
  },[keyName])

  useEffect(()=>{
    if(isConnected){
      setKeyName('pfpArtifact_'+address)
    } else {
      setPFP('avatar.png')
      setKeyName('avatar')
    }
  }, [isConnected])

  useEffect(() => {
    const fetchData = async () => {
      await fetchNFTData();
    };
    fetchData();
  }, [])

  return (
    <HomeStyled>
      <Stack spacing={1}>
        <Box className="title">
          <Typography className="small-title">WELCOME</Typography>
          <Typography className="big-title">MOONIZEN</Typography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xl={4} md={12} xs={12}>
              <Stack spacing={3}>
                <ProfileBox isConnected={isConnected} profileImage={pfp}/>
                <ClaimBox isConnected={isConnected} onClaim={handleClaimAction}/>
              </Stack>
            </Grid>
            <Grid item xl={8} md={12} xs={12}>
              <Box>
                <Stack spacing={3}>
                  <Box>
                    <Grid container className="owned-box" spacing={2}>
                      <Grid item lg={3} xs={12}>
                        <GradientBorderBox className="owned-box-item">
                          <MoonizensOwned isConnected={isConnected} />
                        </GradientBorderBox>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <GradientBorderBox className="owned-box-item quadrocar">
                          <Quadrocar isConnected={isConnected}/>
                        </GradientBorderBox>
                      </Grid>
                      <Grid item lg={3} xs={12}>
                        <GradientBorderBox className="owned-box-item-stats">
                          <div className="stat-text"> 
                            <h3 className="stats-title">Moonizen: Genesis</h3>
                            <p>{maxSupply}</p>
                            <p>{leftSupply}</p>
                            <p>PRICE: 165 POL</p>
                            </div>
                        </GradientBorderBox>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <WalletViewer isConnected={isConnected} onPFPChange={handlepfpChange} claimAction={claimAction}/>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </HomeStyled>
  );
};

export default Home;
