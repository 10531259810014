import { Box, Button, Stack, Tab, Tabs } from '@mui/material';
import SidebarStyled from './index.styled';
import { useDisconnect } from 'wagmi'

import ImageHome from '../../assets/images/home.png';
import ImageMint from '../../assets/images/mint.png';
import ImageDashboard from '../../assets/images/dashboad.png';
import ImageCollection from '../../assets/images/collection.png';
import ImageMedia from '../../assets/images/media.png';
import ImageDoggyMart from '../../assets/images/Doggy_Mart.png';
import ImageOpenSea from '../../assets/images/OpenSea.png';
import ImageGenesisIcon from '../../assets/images/genesis-icon.svg';
import ImageWallet from '../../assets/images/wallet.png';
import ImageSettings from '../../assets/images/settings.png';
import ImageExit from '../../assets/images/exit.png';
import GradientBorderBox from '../GradientBorderBox';

const menuList1 = [
  { icons: [ImageHome, ImageHome], value: 0, label: 'Home' },
  { icons: [ImageMint, ImageMint], value: 1, label: 'Mint' },
  /* { icons: [ImageDashboard, ImageDashboard], value: 2, label: 'Dashboard' }, */
  /* { icons: [ImageCollection, ImageCollection], value: 3, label: 'Collection' }, */
];

const menuList2 = [
  {icons: [ImageMedia, ImageMedia], value:4, label: 'Media', link: "https://media.moonizens.com"},
]

const menuList3 = [
  {icons: [ImageDoggyMart, ImageDoggyMart], value:6, label: 'DoggyMarket', link: "https://doggy.market/nfts/dogizens"},
]

const menuList4 = [
  {icons: [ImageOpenSea, ImageOpenSea], value: 6, label: "Genesis", link: "https://opensea.io/collection/moonizen-genesis"},
  {icons: [ImageOpenSea, ImageOpenSea], value: 7, label: "Legacy", link: "https://opensea.io/collection/moonizen-legacy"},
]

/* const menuList4 = [
  { icon: ImageWallet, value: 8, label: 'Wallet' },
  { icon: ImageSettings, value: 9, label: 'Settings' },
]; */

const menuList6 = [{ icon: ImageExit, value: 10, label: 'Exit' }];

const Sidebar = ({ selectedPage, setSelectedPage, isConnected }) => {
  const { disconnect } = useDisconnect();
/*   const [conn, setConn] = useState(false);

  useEffect(()=>{
    setConn(isConnected)
  },[isConnected]) */

  return (
    <SidebarStyled>
      <GradientBorderBox className="sidebar-box">
        <Box className="sidebar-box-content">
          <Stack
            className="menu-list"
            direction={'column'}
            alignItems={'start'}
            justifyContent={'space-between'}
          >
            <Stack className="menu-wrapper">
              <Tabs
                className="menu"
                value={selectedPage}
                orientation="vertical"
                onChange={(e, value) => {
                  setSelectedPage(value);
                }}
              >
                {menuList1.map((item, index) => {
                  const icon =
                    item.value === selectedPage ? (
                      <img src={item.icons[1]} alt="" />
                    ) : (
                      <img src={item.icons[0]} alt="" />
                    );
                  return (
                    <Tab
                      key={index}
                      value={item.value}
                      label={item.label}
                      icon={icon}
                      className={`menu-item ${
                        item.value === selectedPage
                          ? 'selected gradient-border-box'
                          : ''
                      }`}
                    />
                  );
                })}
              </Tabs>
              <Box
                className = "menu"
                value={selectedPage}
                orientation="vertical"
                onChange={(e ,value) => {
                  setSelectedPage(value);
                }}>
                  {menuList2.map((item,index) => {
                    return(
                      <Button key={index} className="menu-item" onClick={()=>{window.open(item.link, "_blank", "noreferrer")}}>
                        <img src={item.icons[0]} alt="" />
                        {item.label}
                      </Button>
                    )
                  })} </Box>
            </Stack>
            <Stack className="menu-wrapper">
              <Box className='menu-subtitle'>Markets</Box>
              <Box
                className = "menu"
                value={selectedPage}
                orientation="vertical"
                onChange={(e ,value) => {
                  setSelectedPage(value);
                }}>
                  {menuList3.map((item,index) => {
                    return(
                      <Button key={index} className="menu-item" onClick={()=>{window.open(item.link, "_blank", "noreferrer")}}>
                        <img src={item.icons[0]} alt="" />
                        {item.label}
                      </Button>
                    )
                  })}
                  <Box className="menu-subtitle">
                    OpenSea
                    </Box>
                  <Box
                    className = "menu"
                    value={selectedPage}
                    orientation="vertical"
                    onChange={(e ,value) => {
                    setSelectedPage(value);}}>
                      {menuList4.map((item,index) => {
                        return(
                          <Button key={index} className="menu-item" onClick={()=>{window.open(item.link, "_blank", "noreferrer")}}>
                            <img src={item.icons[0]} alt="" />
                            {item.label}
                          </Button>
                        )
                      })}
                    </Box>
              </Box>
            </Stack>
            {/* <Stack className="menu-wrapper">
              <Box
                className="menu"
                value={selectedPage}
                orientation="vertical"
                onChange={(e, value) => {
                  setSelectedPage(value);
                }}
              >
                {menuList3.map((item, index) => {
                  return (
                    <Button key={index} className="menu-item">
                      <img src={item.icon} alt="" />
                      {item.label}
                    </Button>
                  );
                })}
              </Box>
            </Stack> */}
            { isConnected ? (
            <Stack className="menu-wrapper">
              <Box className="menu">
                {menuList6.map((item, index) => {
                  return (
                    <Button key={index} className="menu-item" onClick={() => disconnect()}>
                      <img src={item.icon} alt="" />
                      {item.label}
                    </Button>
                  );
                })}
              </Box>
            </Stack>): (<></>)}
          </Stack>
        </Box>
      </GradientBorderBox>
    </SidebarStyled>
  );
};

export default Sidebar;
