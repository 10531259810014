import { useState, useEffect } from 'react';
import { Box, Stack, Grid, Typography } from '@mui/material';
import { useAccount } from 'wagmi';
import { getMoonizensNFTContract, getMoonizenStudiosContract, fromBigNum, toBigNum } from '../../../services/web3.service';

import WalletViewerStyled from './WalletViewer.styled';
import GenesisAvatar from '../Collection/GenesisAvatar';
import CollectionView from '../Collection/CollectionView';
import LongName from '../../../assets/images/long-container-claim-view.png'
import badge from '../../../assets/images/Dashboard-Badge.png'

import metaData from '../../../abis/metadata.json';
import claimMetaData from '../../../abis/Claims_metadata.json'

const WalletViewer = ({isConnected, onPFPChange, claimAction}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageArray, setImageArray] = useState([]);
  const [modalNft, setModalNft] = useState(metaData.collection[0]);
  const [claimed, setClaimed] = useState(false);
  const [claimsOwned, setClaimsOwned] = useState([]);
  const [claimsEmpty, setClaimsEmpty] = useState(false);
  const { address } = useAccount();
  const signer = null;
  const claimsArray = [claimMetaData.collection[0], claimMetaData.collection[1], claimMetaData.collection[2]]
 
  useEffect(() => {
    if (isConnected) {
      getMetaData();
      getClaimsHeld();
    } else{
      setImageArray([]);
    }

  }, [isConnected, claimAction]);

  const getMetaData = async () => {
    try{
        const contract = await getMoonizensNFTContract(signer);
        const holdings = await contract.getHoldersHoldings(address);
        const holdingsArray = holdings.map(nft => (metaData.collection[Number(nft)-1]));

        //Used for testing purposes
/*         const holdingsArray = [ 
          metaData.collection[2], 
          metaData.collection[9], 
          metaData.collection[10],
          metaData.collection[12],
          metaData.collection[13],
          metaData.collection[14],
          metaData.collection[15],
          metaData.collection[16],
          metaData.collection[17],
          metaData.collection[18],
          metaData.collection[19],
          metaData.collection[20],
          metaData.collection[21],
          metaData.collection[22],
          metaData.collection[23],
          metaData.collection[24],
          metaData.collection[25],
          metaData.collection[26],
          metaData.collection[27],
          metaData.collection[28],
          metaData.collection[29],
          metaData.collection[30],
        ];  */

        setImageArray(holdingsArray);
        /* console.log(holdingsArray); */
    } catch (error) {
      console.error('Error fetching metadata:', error);
    }
  }

    const getClaimsHeld = async () => {
      try {
        const contract = await getMoonizenStudiosContract(signer);
        let tempClaimsOwned = [];
    
        for (let i = 0; i < claimsArray.length; i++) {
          const claimsHeld = await contract.balanceOf(address, claimsArray[i].id);
          const quantity = parseInt(claimsHeld._hex, 16);
          if (quantity > 0) {
            tempClaimsOwned.push({...claimsArray[i], quantity});
          }
        }
    
        setClaimsOwned(tempClaimsOwned);
        setClaimsEmpty(tempClaimsOwned.length === 0);
      } catch (error) {
        console.error('Error fetching claims metadata:', error);
        setClaimsEmpty(true);
      }
    }

  return (
    <WalletViewerStyled>
      <CollectionView isOpen={isModalOpen} setIsOpen={setIsModalOpen} nft={modalNft} onPFPChange={onPFPChange} claimed={claimed}/>

      <Stack>
        <Stack
          className="title title-bottom-bar"
          direction={'row'}
          spacing={1}
          alignItems={'center'}
        >
          <Typography className="genesis-title">Wallet Viewer</Typography>
        </Stack>
        <Grid container spacing={4} className="content">
          
          <Grid item xl={12} className="gradient-border-box genesis-box">
            <Box className="extended gradient-border-box genesis-wrapper">

              {isConnected ? (<><Box className="title">
                <Typography className="genesis-subtitle">GeNeSiS COLLeCTiON</Typography>
              </Box>
              <Grid container spacing={2.5} className="extended wallet-views">
                {imageArray.map((item, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xl={12 / 5}
                      lg={3}
                      md={4}
                      sm={4}
                      xs={6}
                      className="avatar-item"
                    >
                      <GenesisAvatar
                        onClick={() => (setIsModalOpen(true), setModalNft(item), setClaimed(false))}
                        src={require(`../../../assets/images/Moonizen Genesis Thumbnails/${item.image}`)}
                      />
                    </Grid>
                    
                  )
                })}
              </Grid></>):(
                <></>
              ) }
              {isConnected? ( 
                claimsEmpty? (<></>):(<><Box className="title">
                <Typography className="genesis-subtitle secondary">LeGACY COLLeCTiON</Typography>
              </Box>
              <Grid container spacing={2.5} className="extended wallet-views">
                {claimsOwned.map((item, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xl={12 / 5}
                      lg={3}
                      md={4}
                      sm={4}
                      xs={6}
                      className="avatar-item"
                    >
                      <Box className="image-wrapper">
                        <GenesisAvatar
                          onClick={() => (setIsModalOpen(true), setModalNft(item), setClaimed(true))}
                          src={require(`../../../assets/images/Claims Images/${item.image}`)}
                        />
                        <Box className="badge-container">
                          <img src={badge} alt="Badge" className="badge-icon" />
                          <span className="badge-number">{item.quantity}</span>
                        </Box>
                        <Box className="name-container">
                          <img src={LongName} className='name-background'/>
                          <span className="claim-name">{item.name}</span>
                        </Box>
                      </Box>
                    </Grid>
                    
                  )
                })}
              </Grid></>)):(
                <><Box className="title">
                <Typography className="genesis-subtitle secondary">LeGACY COLLeCTiON</Typography>
              </Box>
              <Grid container spacing={2.5} className="extended wallet-views">
                {claimsArray.map((item, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xl={12 / 5}
                      lg={3}
                      md={4}
                      sm={4}
                      xs={6}
                      className="avatar-item"
                    >
                      <Box className="image-wrapper">
                        <GenesisAvatar
                          onClick={() => (setIsModalOpen(false), setModalNft(item), setClaimed(true))}
                          src={require(`../../../assets/images/Claims Images/${item.image}`)}
                        />
                        <Box className="name-container">
                          <img src={LongName} className='name-background'/>
                          <span className="claim-name">{item.name}</span>
                        </Box>
                      </Box>
                    </Grid>
                    
                  )
                })}
              </Grid></>
              )}
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </WalletViewerStyled>
  );
};

export default WalletViewer;