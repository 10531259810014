import { Box, Stack, Grid, Typography } from '@mui/material';
import { getMoonizensNFTContract } from '../../../services/web3.service';
import metaData from '../../../abis/metadata.json';
import { useState, useEffect } from 'react';
import { useAccount } from 'wagmi';

import GenesisAsideStyled from './GenesisAsideStyled';
import ImageGenesisLogo from '../../../assets/images/genesis-icon.svg';
import GenesisAvatar from './GenesisAvatar';
import GenesisRightAside from './GenesisRightAside';

import ImageAvatar1 from '../../../assets/images/table-image-1.png';
import ImageAvatar2 from '../../../assets/images/table-image-2.png';
import ImageAvatar3 from '../../../assets/images/table-image-3.png';
import ImageAvatar4 from '../../../assets/images/table-image-4.png';
import ImageAvatar5 from '../../../assets/images/table-image-5.png';
import CollectionView from './CollectionView';

/* const data = [
  ImageAvatar1,
  ImageAvatar2,
  ImageAvatar3,
  ImageAvatar4,
  ImageAvatar5,
  ImageAvatar1,
  ImageAvatar2,
  ImageAvatar3,
  ImageAvatar4,
  ImageAvatar5,
  ImageAvatar1,
  ImageAvatar2,
  ImageAvatar3,
  ImageAvatar4,
  ImageAvatar5,
]; */

const GenesisAside = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageArray, setImageArray] = useState([]);
  const [modalNft, setModalNft] = useState(metaData.collection[0]);
  const { address, isConnected } = useAccount();
  const signer = null;

  useEffect(() => {
    if (isConnected) {
      getMetaData();
    } else{
      setImageArray([]);
    }
  })

  const getMetaData = async() => {
    try{
      const contract = await getMoonizensNFTContract(signer);
      const holdings = await contract.getHoldersHoldings(address);
      const holdingsArray = holdings.map(nft => (metaData.collection[Number(nft)-1]));

      setImageArray(holdingsArray);
    } catch(error) {
      console.error('Error fetching metadata:', error);
    }
  }

  return (
    <GenesisAsideStyled>
      <CollectionView isOpen={isModalOpen} setIsOpen={setIsModalOpen} nft={modalNft} />

      <Stack>
        <Stack
          className="title title-bottom-bar"
          direction={'row'}
          spacing={1}
          alignItems={'center'}
        >
          <img className="title-logo" src={ImageGenesisLogo} alt="" />
          <Typography className="genesis-title">Genesis</Typography>
        </Stack>
        <Grid container spacing={4} className="content">
          <Grid item lg={9} className="gradient-border-box genesis-box">
            <Box className="gradient-border-box genesis-wrapper">
              <Grid container spacing={2.5}>
                {imageArray.map((item, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xl={12 / 5}
                      lg={3}
                      md={4}
                      sm={4}
                      xs={6}
                      className="avatar-item"
                    >
                      <GenesisAvatar
                        onClick={() => (setIsModalOpen(true), setModalNft(item))}
                        src={require(`../../../assets/images/Moonizen Genesis Images/${item.image}`)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={3} className="genesis-right-wrapper">
            <GenesisRightAside />
          </Grid>
        </Grid>
      </Stack>
    </GenesisAsideStyled>
  );
};

export default GenesisAside;
