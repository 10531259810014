import React from 'react';
import { Box } from '@mui/material';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './StateConsole.module.css';
import './Slider.css';

import Background from '../../../../../assets/images/State-console.png';

const StateConsole = ({ variant = 'text', text, nfts }) => {
  const settings = {
    infinite: true,
    centerMode: true,
    centerPadding: '10%',
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className={styles.consoleWrapper}>
      <img src={Background} alt="" className={styles.background} />
      <Box className={styles.content}>
        {variant === 'text' ? (
          <p className={styles.consoleText}>{text}</p>
        ) : (
          <Slider {...settings} style={{marginTop: "10px"}}>
            {nfts.map((item, index) => (
              <Box px={1} key={index}>
                <Box className="itemContainer">                  
                  <img src={item.image} alt="nft" className='slickItemImage' />
                </Box>
              </Box>
            ))}
          </Slider>
        )}
      </Box>
    </div>
  );
};

export default StateConsole;
