import { useEffect } from 'react';
import { Box, Typography, Modal, Stack, Button, Grid } from '@mui/material';
import CollectionViewStyled from './CollectionView.styled';
import GradientBorderBox from '../../GradientBorderBox';

import metaData from '../../../abis/metadata.json';
import claimMetaData from '../../../abis/Claims_metadata.json';

import ImageModalAvatar from '../../../assets/images/modal-avatar.png';
import Traits from './Traits';

const CollectionView = ({ isOpen, setIsOpen, nft, onPFPChange, claimed }) => {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <CollectionViewStyled>
        <Box className="modal-inner-wrapper">
          <Box className="modal-inner">
            <GradientBorderBox className="modal-content">
              <Box className="box-content">
                <GradientBorderBox className="top-header-box">
                  <Stack direction={'row'} spacing={4.5} className="top-box">
                    <Box className="centering">
                      <GradientBorderBox className="image">
                        {claimed? (
                          <img src={require(`../../../assets/images/Claims Images/${nft.image}`)} alt="" /> 
                      ):(
                        <img src={require(`../../../assets/images/Moonizen Genesis Images/${nft.image}`)} alt="" /> 
                      )}
                      </GradientBorderBox>
                    </Box>
                    <Box className="top-header-desc">
                      <Typography className="bg-title">
                        {claimed? (
                          `${claimMetaData.name}`
                        ):(
                          `${metaData.name}`
                        )}
                      </Typography>
                      <Typography mt={1 / 2} className="sm-title">
                        {claimed? (
                          `NAME: ${nft.name}`
                        ):(
                          `MOONIZEN ${nft.name}`)}
                      </Typography>
                      <Typography mt={1 / 2} className="sm-title">
                      </Typography>
                      <Typography mt={1 / 2} className="sm-title">
                        CATEGORY: ART
                      </Typography>
                      <Typography mt={3} className="content" style={{paddingRight:10}}>
                        {nft.description}
                      </Typography>
                    </Box>
                  </Stack>
                </GradientBorderBox>
                <Box mt={2} sx={{paddingBottom:'20px'}}>
                  <Traits traits={nft.attributes}/>
                </Box>
              </Box>
              <Grid container spacing={2} justifyContent="center"> 
                {claimed? (
                  <Box className="spacer">
                    <Grid item>
                      <Button className="close-button" onClick={() => setIsOpen(false)}>CLOSE</Button>
                    </Grid>
                  </Box>
                  ):(
                  <>
                    <Grid item>
                      <Button className="close-button" onClick={() => setIsOpen(false)}>CLOSE</Button>
                    </Grid>
                      <Grid item>
                        <Button className="close-button" onClick={() => onPFPChange(nft.image)}> SET PROFILE PICTURE</Button>
                      </Grid>
                  </>
                  )}
              </Grid>
            </GradientBorderBox>
          </Box>
        </Box>
      </CollectionViewStyled>
    </Modal>
  );
};

export default CollectionView;
