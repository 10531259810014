/* eslint no-unused-vars:1 */

import { useState, useEffect } from 'react';
import { useAccount } from 'wagmi';
import { getBalance } from '../../../services/web3.service';

import { Box, Stack, Typography } from '@mui/material';
import ProfileBoxStyled from './ProfileBox.styled';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import ImageAvatar from '../../../assets/images/avatar.png';
import AddressBox from '../../AddressBox';
import GradientBorderBox from '../../GradientBorderBox';
import ImageIconGenisis from '../../../assets/images/moonizens-genesis.svg';

const ProfileBox = ({isConnected, profileImage}) => {

  const [ balance, setBalance] = useState('');
  const [ display, setDisplay] = useState();
  const [ visible, setVisible] = useState(true);
  const [ set, setSet] = useState(false);
  const { address } = useAccount();

  const displayBalance = async () => {
    if(isConnected){
      const __balance = await getBalance(address);
      setBalance(__balance.toFixed(4));
      setDisplay(__balance.toFixed(4));
    } 
    else {
      setBalance('0');
      setDisplay('0');
    }
  };  
  const displayVisible = () => {
    if(visible){
      setDisplay(balance.toString());
    }
    else{
      setDisplay('******');
    }
  }

  useEffect(() => {
    displayBalance();
    if(!isConnected){
      setSet(false);
    } else setSet(false);
  }, [isConnected]); 

  useEffect(() => {
    displayVisible();
  }, [visible]);

  return (
    <ProfileBoxStyled>
      <GradientBorderBox className="profile-box">
        <Box className="profile-info">
          <Box className="image">
            <img src={require(`../../../assets/images/${profileImage}`)} alt=""/>
          </Box>
          <Box className="image-two">
            <img src={ImageIconGenisis} alt = "" />
          </Box>
          {/* <Box className="info">
            <Typography className="rank">RANK</Typography>
            <Typography className="value">#1</Typography>
          </Box> */}
        </Box>
        <Box className="balance">
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Box>
              <Typography className="desc">Your Balance:</Typography>
              <Typography className="value" sx={{display:'flex'}}>
                {display} POL 
                <Box className='visible-icon'>{visible? <VisibilityIcon onClick={() => setVisible(!visible)}/> 
                : <VisibilityOffIcon onClick={() => setVisible(!visible)}/>}
                </Box>
              </Typography>
            </Box>
            <AddressBox value={address} isConnected={isConnected} />
          </Stack>
        </Box>
      </GradientBorderBox>
    </ProfileBoxStyled>
  );
};

export default ProfileBox;
