import styled from '@emotion/styled';
import pfpbg from "../../../assets/images/PFPbackground.png";

const ProfileBoxStyled = styled.div`
  .profile-box {
    border-radius: 4px;
    .profile-info {
      padding-left: 38px;
      padding-top: 22px;
      padding-bottom: 50px;
      border-radius: 4px;
      background-image: url(${pfpbg});
      background-size: cover;
      );

      .image {
        width: 137px;
        height: 137px;
        border-radius: 100%;
        border: 2px solid #47c3c1;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .image-two {
        position: absolute;
        width: 100px;
        height: 100px;
        overflow: hidden;
        opacity: 70%;
        top: 15%;
        right: 10%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .info {
        position: absolute;
        top: 36px;
        right: 19px;

        .rank {
          color: ${(props) => props.theme.palette.fontColor.third};
          font-family: Space Ranger;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.15px;
          text-align: right;
        }
        .value {
          color: ${(props) => props.theme.palette.fontColor.secondary};
          text-align: right;
          font-family: Space Ranger;
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.3px;
        }
      }
    }
    .balance {
      border-radius: 0px 0px 4px 4px;
      background: ${(props) => props.theme.palette.background.secondary};
      padding: 16px 18px 39px 16px;

      .desc {
        color: ${(props) => props.theme.palette.fontColor.third};
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.13px;
      }
      .value {
        color: ${(props) => props.theme.palette.fontColor.secondary};
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        
        .visible-icon{
        display: flex;
        padding: 2px;
        }
      } 
    }
  }

  @media (max-width:380px){
    .profile-box .balance{
      .value{
      font-size:16px;
      }
      .visible-icon{
        margin-top:-2px;
      }
    } 

    .profile-box .profile-info{
        padding-left: 25px;
      }

  }
`;

export default ProfileBoxStyled;
