import styled from '@emotion/styled';

const AvatarStyled = styled.div`
  .avatar-box {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 2px solid #47c3c1;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export default AvatarStyled;
