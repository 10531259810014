import styled from '@emotion/styled';

const TabPanelStyled = styled.div`
  padding: 32px 0px;
  background: transparent;

  @media screen and (max-width: 767px) {
    padding: 16px;
    margin-bottom: 100px;
  }
`;

export default TabPanelStyled;
