import React from 'react';

import './Logo.css';

import Logo800 from '../../../../../assets/images/logo-800.png';
import Logo500 from '../../../../../assets/images/logo-500.png';

const Logo = ({ size = 'large' }) => {
  return (
    <>
      <img
        className={`logo ${size}`}
        src={Logo800}
        loading="lazy"
        alt=""
        srcSet={`${Logo500} 500w, ${Logo800} 800w`}
      />
    </>
  );
};

export default Logo;
