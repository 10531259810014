import styled from '@emotion/styled';

const MoonizensOwnedStyled = styled.div`
  height: 100%;
  padding-top: 6px;
  padding-bottom: 10px;

  .moonizens-owned-box {
    height: 100%;
    align-items: center;

    .icon{

    }

    .value {
      color: ${(props) => props.theme.palette.fontColor.primary};
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.2px;
    }
    .desc {
      color: ${(props) => props.theme.palette.fontColor.third};
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }  
  }

  @media (max-width: 1199px){

    .moonizens-owned-box .icon{
      margin-left: auto;
      margin-right: auto;
    }

    .moonizens-owned-box .value{
      margin-left: auto;
      margin-right: auto;
    }

    .moonizens-owned-box .desc{
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export default MoonizensOwnedStyled;
