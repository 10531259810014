import styled from '@emotion/styled';

const WalletViewerStyled = styled.div`
  padding-top: 28px;

  .title {
    padding-bottom: 8px !important;

    .genesis-title {
      color: ${(props) => props.theme.palette.fontColor.secondary};
      font-size: 30px;
      font-weight: 400;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      font-family: Space Age;
    }
    
    .genesis-subtitle {
      color: ${(props) => props.theme.palette.fontColor.secondary};
      font-size: 30px;
      font-weight: 400;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      font-family: Space Ranger;
      text-decoration: underline;
    }

    .secondary{
      padding-top: 30px;
    }
  }

  .content {
    padding-top: 70px;
    padding-left: 32px;

    .extended {
      overflow-y: scroll;

      ::-webkit-scrollbar{
        width: 1px;
      }

      ::-webkit-scrollbar-track{
        border-radius: 10px;
        background: ${(props) => props.theme.palette.background.darkgreen};
      }

      ::-webkit-scrollbar-thumb{
        background: ${(props) => props.theme.palette.fontColor.secondary};
        border-radius: 10px;
      }
    }

    .wallet-views{
      max-height: 450px;
      margin-top: 0px;
    }

    .image-wrapper{
      position:relative;
      height:100%;
      
      .badge-container{
        position:absolute;
        top: 8px;
        right: 8px;
        max-height: 30px;
        max-width: 30px;
        border-radius: 50%;
        z-index: 9999;

        .badge-icon{
          max-height: 25px;
          max-width: 25px;
        }
        
        .badge-number {
          position: absolute;
          max-height: 85%;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .name-container{
        position:absolute;
        top: 8px;
        left: 8px;
        max-height: 30px;
        max-width: 60%;
        border-radius: 50%;
        z-index: 9999;

        .name-background{
        max-height:30px;
        max-width: 100%;
        }

        .claim-name {
          text-align: center;
          position: absolute;
          max-height: 85%;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 9px;
          font-weight: bold;
        }
      }
    }

    

    .genesis-box {
      padding: 25px 30px;
      height: contain; /* 556px */
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        rgba(7, 72, 91, 0.6) 0%,
        rgba(7, 72, 91, 0.2) 100%
      );
      background-blend-mode: multiply;

      .genesis-wrapper {
        padding: 12px 20px;
        border-radius: 10px;
        max-width: 100%; /* 595px; */
        height: contain;
        margin: auto;
        background: #011c23;
      }
    }

    .genesis-right-wrapper {
      padding-top: 0;
    }
  }

  @media (max-width: 1365px) {
    .content .genesis-box {
      height: contain; /* 500px */
    }

    .content .wallet-views{
      max-height: 385px;
    }
  }

  @media (max-width: 1200px) {
    padding-top:0px;

    .content .genesis-box {
      padding: 30px;
      width: 100%;
    }
    .gradient-border-box .bg-box {
      min-width: unset;
    }
  }

  @media (max-width: 991px) {
    .content .genesis-box {
      height: contain; /* 800px */
      width: 100%;
    }

    .content .wallet-views{
      max-height: 680px;
    }

  }

  @media (max-width: 768px){
    .content .image-wrapper .name-container{
      max-width: 55%;

      .name-background{
        height: 25px;

        .claim-name{
          font-size: 8px;
        }
      }
    }
  }

  @media (max-width: 574px) {
    .content .genesis-box {
      height: contain; /* 580px */
    }

    .content .wallet-views{
      max-height: 470px;
    }

    .title .genesis-subtitle{
      font-size: 20px;
    }
  }

  @media (max-width: 375px){
    .content .image-wrapper .name-container{
      max-width: 50%;

      .claim-name{
        font-size: 7px;
      }
    }
  }
`;

export default WalletViewerStyled;
