import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { RainbowKitProvider, AvatarComponent, darkTheme } from '@rainbow-me/rainbowkit';
import { WagmiConfig } from 'wagmi';
import { config, wagmiConfig, chains } from './configs/connectors';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import rainbowAv from './assets/images/avatar.png';

import './index.css';
import '@rainbow-me/rainbowkit/styles.css';

const queryClient = new QueryClient()

const CustomAvatar = ({ensImage, size}) => {
  const color = "#07232c";
  return ensImage ? (
    <img src={ensImage} width={size} height={size} style={{borderRadius: 999}} />
  ):(
    <div style={{ backgroundColor: color, borderRadius:999, width: size, height: size}}> c: </div>
  )
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider chains={chains} theme={darkTheme()}>
            <App />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
