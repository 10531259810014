import styled from '@emotion/styled';

const TraitsStyled = styled.div`
  .traits-box {
    .title {
      
      p {
        color: ${(props) => props.theme.palette.fontColor.primary};
        font-family: Space Ranger;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.24px;
        justify-content: center; 
        text-align:center;
      }
    }

    .display-form {
      .display-form-item {
        border-radius: 4px;
        background: #000d14;
        background-blend-mode: multiply;

        .item {
          text-align:center;
          transform:translate(0%, 15%);
          margin: 2px 0px;
          height: 43px;
          padding: 2px 10px;
          color: #fff;
        }
      }

      .display-form-title{
        text-align: center;
        height: 28px;
        border-radius: 4px;
        background-blend-mode: multiply;
        color: #fff;
        font-size: 14px;
        padding: 2px 5px;
      }
        
      .item-container{
        padding-left: 54px;
      }
    }


      .content {
        border-radius: 5px;
        background: rgba(7, 72, 91, 0.25);
        background-blend-mode: multiply;
        padding: 20px 20px 10px 20px;

        p {
          color: #fff;
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.13px;
        }

        p.bold {
          font-weight: 700;
        }
        p.small {
          color: #fff;
          font-family: Jura;
          font-size: 8px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          word-wrap: break-word;
        }
      }
    }
  }
  
  @media(max-width: 424px){
    .traits-box .display-form .item-container{
      margin-left: calc(0.24 * 100% - 90px);
    }
  }
`;

export default TraitsStyled;
