import { useState, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useAccount } from 'wagmi';

import { getMoonizensNFTContract } from '../../../services/web3.service';
import metaData from '../../../abis/metadata.json';

import QuadrocarStyled from './Quadrocar.styled';

import ImageQuadrocar4 from '../../../assets/images/quadrocar-1.png';
import ImageQuadrocar2 from '../../../assets/images/quadrocar-2.png';
import ImageQuadrocar3 from '../../../assets/images/quadrocar-3.png';
import ImageQuadrocar1 from '../../../assets/images/quadrocar-4.png';

const Quadrocar = ({isConnected}) => {

  const { address } = useAccount();
  const [quadOne, setQuadOne] = useState(false);
  const [quadTwo, setQuadTwo] = useState(false);
  const [quadThree, setQuadThree] = useState(false);
  const [quadFour, setQuadFour] = useState(false);
  const [touch, setTouch] = useState(false);
  const signer = null;

  useEffect(()=>{
    if(isConnected){
      const fetchMetaData= async () => {
        await getMetaData();
      }
      fetchMetaData();
    } else {
      setQuadOne(false);
      setQuadTwo(false);
      setQuadThree(false);
      setQuadFour(false);
    }
  }, [isConnected])

  const getMetaData = async () => {
    try{
        const contract = await getMoonizensNFTContract(signer);
        const holdings = await contract.getHoldersHoldings(address);
        const holdingsArray = holdings.map(nft => (metaData.collection[Number(nft)-1]));

        //Used for testing the quadrocar indicators
        /* const holdingsArray = [ metaData.collection[2], metaData.collection[9], metaData.collection[10]]; */

        for(let i = 0; i< holdingsArray.length; i++){
          if(holdingsArray[i].attributes.length >=9){
            if(holdingsArray[i].attributes[8].value === "Quadrocar I"){
              setQuadOne(true);
            }
            if(holdingsArray[i].attributes[8].value === "Quadrocar II"){
              setQuadTwo(true);
            }
            if(holdingsArray[i].attributes[8].value === "Quadrocar III"){
              setQuadThree(true);
            }
            if(holdingsArray[i].attributes[8].value === "Quadrocar IV"){
              setQuadFour(true);
            }
          }
        }
      } catch (error) {
      console.error('Error geting Quadrocar information:', error);
      setQuadOne(false);
      setQuadTwo(false);
      setQuadThree(false);
      setQuadFour(false);
    }
  }

  const QuadrocarOne = quadOne ? ({image: ImageQuadrocar1, class: "quadrocar-item-on", number:"I"}):({image: ImageQuadrocar1, class: "quadrocar-item-off", number:"I"})
  const QuadrocarTwo = quadTwo ? ({image: ImageQuadrocar2, class: "quadrocar-item-on", number: "II"}):({image: ImageQuadrocar2, class: "quadrocar-item-off", number:"II"})
  const QuadrocarThree = quadThree ? ({image: ImageQuadrocar3, class: "quadrocar-item-on", number:"III"}):({image: ImageQuadrocar3, class: "quadrocar-item-off", number:"III"})
  const QuadrocarFour = quadFour ? ({image: ImageQuadrocar4, class: "quadrocar-item-on", number:"IV"}):({image: ImageQuadrocar4, class: "quadrocar-item-off", number:"IV"})


  const images = [
    QuadrocarOne,
    QuadrocarTwo,
    QuadrocarThree,
    QuadrocarFour,
  ];

  return (
    <QuadrocarStyled>
      <Stack
        className="quadrocar-box"
        direction={'column'}
        justifyContent={'space-between'}
      >
        <Typography className="title">Quadrocar</Typography>
        <Stack
          className="quadrocar-items"
          direction={'row'}
          justifyContent={'space-between'}
        >
          {images.map((item, index) => {
            return (
              <Box key={index} className="image-container">
                <Box className={item.class}>
                  <img src={item.image} alt="" />
                </Box>
                <Box className="overlay">
                  {item.number}
                </Box>
              </Box>
            );
          })}
        </Stack>
      </Stack>
    </QuadrocarStyled>
  );
};

export default Quadrocar;
