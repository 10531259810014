import styled from '@emotion/styled';

const HomeStyled = styled.div`
  padding-top: 8px;
  .owned-box {
    .owned-box-item {
      height: 135px;
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        rgba(7, 72, 91, 0.6) 0%,
        rgba(7, 72, 91, 0.2) 100%
      );
    }
    
    .owned-box-item-stats {
      height: 135px;
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        rgba(7, 72, 91, 0.6) 0%,
        rgba(7, 72, 91, 0.2) 100%
      );
      font-family: "Poppins-Bold";
      color: #47c3c1;
      font-weight: bold;
      font-size: 13px;
      white-space: 'pre-line';
      word-break: 'break-word';
      align-content: 'space-around';

      .stats-title{
        color:${(props) => props.theme.palette.fontColor.secondary};
        paddingBottom:"10px"
      }

      .stat-text{
        margin-left: 5px;
      }
    }
  }

  .p{
    font-family: "Poppins-Bold";
  }

  @media (max-width: 1870px) {
    .owned-box .owned-box-item-stats{
      font-size: 11px;
    }
  }

  @media (max-width: 1440px) {
    .owned-box .owned-box-item-stats{
      font-size: 10px;
    }
  }

  @media (max-width: 1199px) {
    .owned-box,
    .recent-transactions-box {
      margin-top: 20px;
    }
  }

  @media (max-width: 985px){
    .owned-box .owned-box-item-stats .stats-title{
      font-size:16px;
    }

    .owned-box .owned-box-item-stats{
      font-size:12px;
    }
  }

  @media (max-width: 574px) {
    .owned-box .owned-box-item.quadrocar {
      height: fit-content;
    }
  }
`;

export default HomeStyled;
